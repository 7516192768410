import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setReviewSubHeader } from "../Redux/reviewRedux";
import "./sl.css";

const StoreLocation = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setReviewSubHeader("Store location"));
  }, []);

  return (
    <div className="top-div-sl">
      <div className="sl">
        <table>
          <thead>
            <tr>
              <th>Store No.</th>
              <th>City</th>
              <th>Locality</th>
              <th>Address</th>
              <th>Open hrs.</th>
              <th>Google Map Link</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Chennai</td>
              <td>Mugalivakkam</td>
              <td style={{ width: "400px" }}>
                Ganesh Swami Store, Shop No. 77, 3rd Main Rd, Subasree Nagar,
                Mugalivakkam, Chennai, Tamil Nadu 600116. Landmark: Near Kedar
                Hospital
              </td>
              <td>9am to 6pm (Mon to Sat)</td>
              <td
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://maps.app.goo.gl/ZXagM6PfWthweFgL7")
                }
              >
                <LocationOnIcon style={{ fontSize: "3rem" }} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StoreLocation;
